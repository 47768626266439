export const FirebaseConfig = {
	"projectId": "lyftnet-a06ce",
	"appId": "1:545210372582:web:cb0b268f476a8101e04627",
	"databaseURL": "https://lyftnet-a06ce-default-rtdb.firebaseio.com",
	"storageBucket": "lyftnet-a06ce.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyAF7GipKZX-_br5egZQtcKbgwvz-iCzkp8",
	"authDomain": "lyftnet-a06ce.firebaseapp.com",
	"messagingSenderId": "545210372582",
	"measurementId": "G-KCCSL5EGXJ"
};